import React from "react";

import { footer } from "./ArticleFooter.module.scss";

import Link from "../components/Link";

const ArticleFooter = props => {
  const pageUrl = typeof window !== "undefined" ? window.location.href : "";

  console.log({ pageUrl })

  return (
    <footer className={footer}>
      <p>Share this article:</p>
      <Link external href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}>
        <img src="/images/linkedin-logo-navy.svg" alt="Linkedin logo" />
      </Link>
      <Link external href={`https://twitter.com/intent/tweet?text=${pageUrl}`}>
        <img src="/images/twitter-logo-navy.svg" alt="Twitter logo" />
      </Link>
      <Link external href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}>
        <img src="/images/facebook-logo-navy.svg" alt="Facebook logo" />
      </Link>
    </footer>
  );
};

export default ArticleFooter;
