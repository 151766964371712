import React from "react";

import { header, body, footer } from "./blog-article.module.scss";

import Layout from "../layouts/Layout";
import ArticleFooter from "../partials/ArticleFooter";

import { sanitiseHtml, formatDate } from "../utils/misc";

const BlogArticlePage = props => {
  const {
    pageContext: { blog },
  } = props;

  const subtitle = `Posted by ${blog.author_name} | ${formatDate(blog.publish_date)}`;

  return (
    <Layout
      pageTitle="Blog | Brandcrush"
      ogTitle={blog.title}
      ogDescription={subtitle}
      ogImage={blog.featured_image.src}
    >
      <section className={header}>
        <h1>{blog.title}</h1>
        <p>{subtitle}</p>
        <img src={blog.featured_image} alt={blog.featured_image_alt_text} />
      </section>
      <section
        className={body}
        dangerouslySetInnerHTML={{ __html: sanitiseHtml(blog.post_body) }}
      ></section>
      <ArticleFooter />
    </Layout>
  );
};

export default BlogArticlePage;
